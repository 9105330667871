// Code generated by Slice Machine. DO NOT EDIT.

import Banner1 from './Banner1';
import Banner2 from './Banner2';
import BannerTriptih from './BannerTriptih';
import FotoGalerija from './FotoGalerija';
import KontaktBanner from './KontaktBanner';
import KontaktForma from './KontaktForma';
import Materijali from './Materijali';
import Projekti from './Projekti';
import Slider from './Slider';
import Slika from './Slika';
import Stanovi from './Stanovi';
import Tekst from './Tekst';
import VideoEmbed from './VideoEmbed';

export {
	Banner1,
	Banner2,
	BannerTriptih,
	FotoGalerija,
	KontaktBanner,
	KontaktForma,
	Materijali,
	Projekti,
	Slider,
	Slika,
	Stanovi,
	Tekst,
	VideoEmbed,
};

export const components = {
	banner1: Banner1,
	banner2: Banner2,
	banner_triptih: BannerTriptih,
	foto_galerija: FotoGalerija,
	kontakt_banner: KontaktBanner,
	kontakt_forma: KontaktForma,
	materijali: Materijali,
	projekti: Projekti,
	slider: Slider,
	slika: Slika,
	stanovi: Stanovi,
	tekst: Tekst,
	video_embed: VideoEmbed,
};
