import React from 'react';

export const Svg = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" {...props}>
      {props.children}
    </svg>
  );
}

export const LogoTop = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336 137" className='logo'>
      <path d="M83.5 94.2 48.3.7a1 1 0 0 0-1-.6H36.2a1 1 0 0 0-1 .6L0 94.2a1 1 0 0 0 0 1 1 1 0 0 0 1 .5h16.5a1 1 0 0 0 1-.7l5.9-17H59l6 17a1 1 0 0 0 1 .7h16.5a1 1 0 0 0 1-1.5ZM53.2 61.1H30.3l11.5-33ZM164.7 94l-21.4-38.5a25 25 0 0 0 4.4-2 24.6 24.6 0 0 0 9.7-10.1 31.5 31.5 0 0 0 3.5-15 31.4 31.4 0 0 0-3.5-14.8 24.7 24.7 0 0 0-9.7-10.1A28.7 28.7 0 0 0 133.3 0h-39a1 1 0 0 0-1 1v93.6a1 1 0 0 0 1 1h15.4a1 1 0 0 0 1-1V57H124l20.2 38a1 1 0 0 0 1 .5h18.4a1 1 0 0 0 1-.5 1 1 0 0 0 0-1Zm-22-65.5a15.6 15.6 0 0 1-1.3 6.3 9 9 0 0 1-8.5 5.4h-22.2V16.8h22.2a9.5 9.5 0 0 1 5.2 1.4 9.3 9.3 0 0 1 3.4 4 14.7 14.7 0 0 1 1.3 6.3ZM253 94l-32.5-55.2 28.4-37a1 1 0 0 0 .1-1.2 1 1 0 0 0-1-.5h-19a1 1 0 0 0-1 .4l-32.5 45.3V1a1 1 0 0 0-1-1H179a1 1 0 0 0-1 1v93.5a1 1 0 0 0 1 1h15.4a1 1 0 0 0 1-1V71.3L208.7 54l24 41a1 1 0 0 0 .9.5H252a1 1 0 0 0 1-.5 1 1 0 0 0 0-1.1Z" fill="#d71d45"/>
      <path d="M336 94.2 300.7.7a1 1 0 0 0-1-.6h-11.2a1 1 0 0 0-1 .6l-35 93.5a1 1 0 0 0 1 1.4H270a1 1 0 0 0 1-.7l5.9-17h34.6l5.9 17a1 1 0 0 0 1 .7H335a1 1 0 0 0 1-1.4Zm-30.3-33.1h-22.9l11.4-33Z" fill="#d71d45"/>
      <path d="M46.8 131.2H36.6l10.5-16 .1-.2v-4.6H29.4v5.5h10.3l-10.6 16-.1.2v4.6h18.6v-5.5h-.8zm42.7-9.7h-10v5.6h4.7a4.8 4.8 0 0 1-.5 2.3 3.3 3.3 0 0 1-1.4 1.4 4.7 4.7 0 0 1-2.3.5 5.3 5.3 0 0 1-2.3-.5 3.4 3.4 0 0 1-1.5-1.3 3.7 3.7 0 0 1-.5-2v-7.3a4.8 4.8 0 0 1 .5-2.3 3.6 3.6 0 0 1 1.5-1.5 4.4 4.4 0 0 1 2.2-.6 4.7 4.7 0 0 1 1.7.4 4 4 0 0 1 1.4.9 3.4 3.4 0 0 1 .8 1.4l.2.6h6.3l-.2-1a10.2 10.2 0 0 0-2-4.2 9.6 9.6 0 0 0-3.6-2.8 11 11 0 0 0-4.6-1 11.2 11.2 0 0 0-5.4 1.2 8.9 8.9 0 0 0-3.7 3.6 10.5 10.5 0 0 0-1.2 5.2v7.3a9.4 9.4 0 0 0 1.3 5 8.8 8.8 0 0 0 3.7 3.4A12 12 0 0 0 80 137a11.3 11.3 0 0 0 5.4-1.3 8.9 8.9 0 0 0 3.6-3.5 10.6 10.6 0 0 0 1.3-5.3v-5.4Zm131.7-6.7a8.4 8.4 0 0 0-3.8-3.3 14.4 14.4 0 0 0-6-1.1H202v26.3h9.4a14.3 14.3 0 0 0 6-1.1 8.7 8.7 0 0 0 3.9-3.4 9.9 9.9 0 0 0 1.3-5.1v-7a9.9 9.9 0 0 0-1.4-5.3Zm-4.7 5.1v7.3a3.6 3.6 0 0 1-1.3 3 5.6 5.6 0 0 1-3.6 1H208V116h3.6a5.6 5.6 0 0 1 3.7 1 3.6 3.6 0 0 1 1.1 3Zm50.6-9.5H262v16.2l-9.5-15.8-.2-.4h-6.1v26.3h5.9v-16l9.5 15.6.2.4h6.1v-26.3h-.8zm21.2 0H283V128a3.7 3.7 0 0 1-.7 2.6 2.8 2.8 0 0 1-2.3.9 4.2 4.2 0 0 1-1.7-.4 3.2 3.2 0 0 1-1.2-1l-.5-.6-.6.4-3.6 2.4-.7.5.5.6a8 8 0 0 0 3.3 2.7 10.6 10.6 0 0 0 4.4.9 10.9 10.9 0 0 0 5-1 7.4 7.4 0 0 0 3.2-3.1 10 10 0 0 0 1-4.8v-17.7Zm-153 25.1-5.7-9.6a6.6 6.6 0 0 0 .7-.4 7.3 7.3 0 0 0 3-2.9 9 9 0 0 0 0-8.4 7.4 7.4 0 0 0-3-2.9 9 9 0 0 0-4.4-1H114v26.4h6v-10.2h3l5.6 9.8.3.4h7.1ZM120 115.8h5.8a2.3 2.3 0 0 1 1.2.4 2 2 0 0 1 .8.8 3 3 0 0 1 .3 1.4 3.2 3.2 0 0 1-.3 1.4 2 2 0 0 1-.8.9 2.3 2.3 0 0 1-1.2.3H120Zm215.6 19.8-10-24.7-.3-.5h-4.2l-.2.5-10 24.7-.5 1.1h6.5l.2-.5 1.5-4.2h9.2l1.6 4.2.2.5h6.4Zm-9.9-9h-5l2.5-6.8Zm-145.4 9-10-24.7-.2-.5h-4.3l-.2.5-10 24.7-.4 1.1h6.4l.2-.5 1.6-4.2h9.1l1.6 4.2.2.5h6.5Zm-9.9-9h-5l2.6-6.8ZM5.3 110.4H0v26.4h6.1v-26.4h-.8z" fill="#454545"/>
    </svg>
  );
}

export const BuildingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
      <path fill="#254260" d="M60 0a15 15 0 0 0-15 15v15H15A15 15 0 0 0 0 45v60a15 15 0 0 0 15 15h90a15 15 0 0 0 15-15V15a15 15 0 0 0-15-15ZM15 71.3a3.8 3.8 0 0 1 3.8-3.8h7.4a3.8 3.8 0 0 1 3.8 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7h-7.4a3.8 3.8 0 0 1-3.8-3.8ZM63.8 75a3.8 3.8 0 0 1-3.8-3.8v-7.5a3.8 3.8 0 0 1 3.8-3.7h7.5a3.8 3.8 0 0 1 3.7 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7ZM90 71.2v-7.5a3.8 3.8 0 0 1 3.8-3.7h7.5a3.8 3.8 0 0 1 3.7 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7h-7.5a3.8 3.8 0 0 1-3.7-3.8ZM18.7 45h7.6a3.8 3.8 0 0 1 3.7 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7h-7.4a3.8 3.8 0 0 1-3.8-3.8v-7.5a3.8 3.8 0 0 1 3.8-3.7ZM60 18.7a3.8 3.8 0 0 1 3.8-3.7h7.5a3.8 3.8 0 0 1 3.7 3.8v7.4a3.8 3.8 0 0 1-3.8 3.8h-7.5a3.8 3.8 0 0 1-3.7-3.8ZM93.8 15h7.5a3.8 3.8 0 0 1 3.7 3.8v7.4a3.8 3.8 0 0 1-3.8 3.8h-7.5a3.8 3.8 0 0 1-3.7-3.8v-7.4a3.8 3.8 0 0 1 3.8-3.8ZM60 48.8v-7.5a3.8 3.8 0 0 1 3.8-3.8h7.5a3.8 3.8 0 0 1 3.7 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7h-7.5a3.8 3.8 0 0 1-3.7-3.8Zm33.8 3.7a3.8 3.8 0 0 1-3.8-3.8v-7.5a3.8 3.8 0 0 1 3.8-3.7h7.5a3.8 3.8 0 0 1 3.7 3.8v7.5a3.8 3.8 0 0 1-3.8 3.7Z"/>
    </svg>
  );
}

export const PhoneSolidIcon = props => {
  return <path d="M21.88,0A18.13,18.13,0,0,1,40,18.13a1.88,1.88,0,0,1-3.75,0A14.38,14.38,0,0,0,21.88,3.75a1.88,1.88,0,1,1,0-3.75Zm.62,15A2.5,2.5,0,1,1,20,17.5,2.5,2.5,0,0,1,22.5,15ZM20,9.38A1.88,1.88,0,0,1,21.88,7.5,10.63,10.63,0,0,1,32.5,18.13a1.88,1.88,0,0,1-3.75,0,6.88,6.88,0,0,0-6.87-6.88A1.87,1.87,0,0,1,20,9.38ZM9.18.11a3.11,3.11,0,0,1,3.7,1.81L16,9.42A3.12,3.12,0,0,1,15.1,13L11.25,16.2A26.37,26.37,0,0,0,23.8,28.75L27,24.9A3.13,3.13,0,0,1,30.57,24l7.5,3.13a3.11,3.11,0,0,1,1.81,3.7L38,37.7A3.13,3.13,0,0,1,35,40,35,35,0,0,1,0,5,3.14,3.14,0,0,1,2.3,2Z" {...props}/>
};

export const PhoneOutlineIcon = props => {
  return <path d="M21.88,0A18.13,18.13,0,0,1,40,18.12a1.88,1.88,0,1,1-3.75,0A14.38,14.38,0,0,0,21.88,3.75a1.88,1.88,0,1,1,0-3.75Zm.62,15A2.5,2.5,0,1,1,20,17.5,2.5,2.5,0,0,1,22.5,15ZM20,9.38A1.88,1.88,0,0,1,21.88,7.5,10.63,10.63,0,0,1,32.5,18.12a1.88,1.88,0,1,1-3.75,0,6.88,6.88,0,0,0-6.87-6.87A1.88,1.88,0,0,1,20,9.38Zm5.7,13a3.11,3.11,0,0,1,3.65-.89l8.75,3.75a3.12,3.12,0,0,1,1.83,3.52l-1.88,8.75A3.12,3.12,0,0,1,35,40h0c-.48,0-1,0-1.42,0h0c-.78,0-1.55-.09-2.31-.17A35,35,0,0,1,0,5H0A3.11,3.11,0,0,1,2.47,2L11.22.07A3.11,3.11,0,0,1,14.74,1.9l3.75,8.75a3.12,3.12,0,0,1-.89,3.65l-3.17,2.59a24.08,24.08,0,0,0,8.68,8.68Zm10.46,6.1-7.85-3.36L26,27.94a3.75,3.75,0,0,1-4.79.86,27.62,27.62,0,0,1-10-10A3.75,3.75,0,0,1,12.05,14l2.81-2.3L11.5,3.84,3.76,5.51A31.25,31.25,0,0,0,34.49,36.25l1.67-7.75Z" {...props}/>
};

export const MailSolidIcon = props => {
  return <path d="M3.75,0A3.75,3.75,0,0,0,1.5,6.75l17,12.75a2.52,2.52,0,0,0,3,0l17-12.75A3.75,3.75,0,0,0,36.25,0ZM0,8.75V25a5,5,0,0,0,5,5H35a5,5,0,0,0,5-5V8.75L23,21.5a5,5,0,0,1-6,0Z" {...props}/>
};

export const MailOutlineIcon = props => {
  return <path d="M5,3.75A1.25,1.25,0,0,0,3.75,5V6.73L17.23,17.79a4.36,4.36,0,0,0,5.55,0L36.25,6.73V5A1.25,1.25,0,0,0,35,3.75ZM3.75,11.58V25A1.25,1.25,0,0,0,5,26.25H35A1.25,1.25,0,0,0,36.25,25V11.58L25.16,20.69a8.14,8.14,0,0,1-10.32,0ZM0,5A5,5,0,0,1,5,0H35a5,5,0,0,1,5,5V25a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5Z" {...props}/>
};

export const GlobeIcon = props => {
  return <path d="M20 36.3c.6 0 2.1-.6 3.7-3.8a22.5 22.5 0 0 0 1.7-5H14.6a22.5 22.5 0 0 0 1.7 5c1.6 3.2 3.1 3.8 3.7 3.8Zm-6-12.5h12.1a34.8 34.8 0 0 0 .2-3.8 34.8 34.8 0 0 0-.2-3.8H14a35.3 35.3 0 0 0 0 7.6Zm.6-11.3h10.8a22.5 22.5 0 0 0-1.7-5c-1.6-3.2-3.1-3.8-3.7-3.8s-2.1.6-3.7 3.8a22.5 22.5 0 0 0-1.7 5ZM30 16.3a38.2 38.2 0 0 1 0 7.4h6a16.5 16.5 0 0 0 .4-3.7 16 16 0 0 0-.5-3.8h-6Zm4.6-3.8a16.3 16.3 0 0 0-7.7-7.3 26.1 26.1 0 0 1 2.5 7.3h5.2Zm-23.7 0a26 26 0 0 1 2.5-7.3 16.3 16.3 0 0 0-7.7 7.3h5.2Zm-6.6 3.8a16.5 16.5 0 0 0-.5 3.7 16 16 0 0 0 .5 3.8h6a35.3 35.3 0 0 1 0-7.6Zm22.5 18.5a16.3 16.3 0 0 0 7.7-7.3h-5.1a26.3 26.3 0 0 1-2.6 7.3Zm-13.4 0a26.1 26.1 0 0 1-2.6-7.3H5.6a16.3 16.3 0 0 0 7.7 7.3ZM20 40a20 20 0 1 1 20-20 20 20 0 0 1-20 20Z" {...props}/>
};

export const HouseSolidIcon = props => {
  return <path d="M37.77,19.66A2.22,2.22,0,0,0,40,17.47a1.85,1.85,0,0,0-.76-1.64l-3.67-3.18V4.38a2.2,2.2,0,0,0-2.22-2.19H31.11a2.2,2.2,0,0,0-2.22,2.19v2.5L21.49.48A2.32,2.32,0,0,0,20,0a2,2,0,0,0-1.53.55L.69,15.83A2.2,2.2,0,0,0,0,17.47a2.18,2.18,0,0,0,2.22,2.19H4.44V32.27A2.75,2.75,0,0,0,7.22,35h5.56a2.76,2.76,0,0,0,2.78-2.73v-6a2.2,2.2,0,0,1,2.22-2.19h4.44a2.21,2.21,0,0,1,2.23,2.19v6A2.75,2.75,0,0,0,27.22,35h5.56a2.75,2.75,0,0,0,2.78-2.73V31.16a5,5,0,0,0,0-.56l-.05-11h2.23Z" {...props}/>
};

export const HouseOutlineIcon = props => {
  return <path d="M18.92.39a1.69,1.69,0,0,1,2.16,0L32.22,9.7V3.83a1.67,1.67,0,1,1,3.34,0v8.65l3.85,3.22A1.62,1.62,0,0,1,39.6,18a1.67,1.67,0,0,1-2.34.19l-1.7-1.42V29.53A5.52,5.52,0,0,1,30,35H10a5.52,5.52,0,0,1-5.56-5.47V16.78L2.75,18.2A1.68,1.68,0,0,1,.4,18,1.62,1.62,0,0,1,.59,15.7ZM7.78,14V29.53A2.2,2.2,0,0,0,10,31.72h3.33V21.33a2.77,2.77,0,0,1,2.78-2.74h7.78a2.77,2.77,0,0,1,2.78,2.74V31.72H30a2.2,2.2,0,0,0,2.22-2.19V14L20,3.79Zm8.89,7.88v9.84h6.66V21.88Z" {...props}/>
};

export const ZgradaIcon = props => {
  return <path d="M60,0A15,15,0,0,0,45,15V30H15A15,15,0,0,0,0,45v60a15,15,0,0,0,15,15h90a15,15,0,0,0,15-15V15A15,15,0,0,0,105,0ZM15,71.25a3.76,3.76,0,0,1,3.75-3.75h7.5A3.76,3.76,0,0,1,30,71.25v7.5a3.76,3.76,0,0,1-3.75,3.75h-7.5A3.76,3.76,0,0,1,15,78.75ZM63.75,75A3.76,3.76,0,0,1,60,71.25v-7.5A3.76,3.76,0,0,1,63.75,60h7.5A3.76,3.76,0,0,1,75,63.75v7.5A3.76,3.76,0,0,1,71.25,75ZM90,71.25v-7.5A3.76,3.76,0,0,1,93.75,60h7.5A3.76,3.76,0,0,1,105,63.75v7.5A3.76,3.76,0,0,1,101.25,75h-7.5A3.76,3.76,0,0,1,90,71.25ZM18.75,45h7.5A3.76,3.76,0,0,1,30,48.75v7.5A3.76,3.76,0,0,1,26.25,60h-7.5A3.76,3.76,0,0,1,15,56.25v-7.5A3.76,3.76,0,0,1,18.75,45ZM60,18.75A3.76,3.76,0,0,1,63.75,15h7.5A3.76,3.76,0,0,1,75,18.75v7.5A3.76,3.76,0,0,1,71.25,30h-7.5A3.76,3.76,0,0,1,60,26.25ZM93.75,15h7.5A3.76,3.76,0,0,1,105,18.75v7.5A3.76,3.76,0,0,1,101.25,30h-7.5A3.76,3.76,0,0,1,90,26.25v-7.5A3.76,3.76,0,0,1,93.75,15ZM60,48.75v-7.5a3.76,3.76,0,0,1,3.75-3.75h7.5A3.76,3.76,0,0,1,75,41.25v7.5a3.76,3.76,0,0,1-3.75,3.75h-7.5A3.76,3.76,0,0,1,60,48.75ZM93.75,52.5A3.76,3.76,0,0,1,90,48.75v-7.5a3.76,3.76,0,0,1,3.75-3.75h7.5A3.76,3.76,0,0,1,105,41.25v7.5a3.76,3.76,0,0,1-3.75,3.75Z" {...props}/>
};



export const YouTubeIcon = props => {
  return <path d="M39.2,4.4c-0.5-1.7-1.8-3.1-3.5-3.5C32.5,0,20,0,20,0S7.5,0,4.4,0.8C2.7,1.3,1.3,2.7,0.8,4.4C0,7.5,0,14,0,14s0,6.5,0.8,9.6
	c0.5,1.7,1.8,3.1,3.5,3.5C7.5,28,20,28,20,28s12.5,0,15.6-0.8c1.7-0.5,3.1-1.8,3.5-3.5C40,20.5,40,14,40,14S40,7.5,39.2,4.4z M16,20
	V8l10.4,6L16,20z" {...props}/>
};

export const FacebookIcon = props => {
  return <path d="M0,21.13v0A21.11,21.11,0,0,0,17.22,42V28.16H12.71a.71.71,0,0,1-.71-.71V21.72a.71.71,0,0,1,.71-.71h4.51v-4c0-5.21,3.26-8.58,8.3-8.58H30a.7.7,0,0,1,.7.71v5.32a.7.7,0,0,1-.7.71H27c-2.08,0-2.46.82-2.46,2.43V21H29.8a.75.75,0,0,1,.5.21.7.7,0,0,1,.2.5v5.73a.71.71,0,0,1-.71.71H24.52V42A21.1,21.1,0,0,0,42,21.17v0a21,21,0,1,0-42,0Z" {...props}/>
};

export const InstagramIcon = props => {
  return <g {...props}><path d="M21,0c-5.7,0-6.42,0-8.66.13a15.42,15.42,0,0,0-5.1,1A10.71,10.71,0,0,0,1.1,7.24a15.42,15.42,0,0,0-1,5.1C0,14.58,0,15.3,0,21s0,6.42.13,8.66a15.42,15.42,0,0,0,1,5.1A10.71,10.71,0,0,0,7.24,40.9a15.42,15.42,0,0,0,5.1,1c2.24.11,3,.13,8.66.13s6.42,0,8.66-.13a15.42,15.42,0,0,0,5.1-1,10.71,10.71,0,0,0,6.14-6.14,15.42,15.42,0,0,0,1-5.1C42,27.42,42,26.7,42,21s0-6.42-.13-8.66a15.42,15.42,0,0,0-1-5.1A10.71,10.71,0,0,0,34.76,1.1a15.42,15.42,0,0,0-5.1-1C27.42,0,26.7,0,21,0Zm0,3.78c5.61,0,6.27,0,8.49.13a11.54,11.54,0,0,1,3.9.72A6.5,6.5,0,0,1,35.8,6.2a6.58,6.58,0,0,1,1.57,2.41,11.54,11.54,0,0,1,.72,3.9c.1,2.22.13,2.88.13,8.49s0,6.27-.13,8.49a11.54,11.54,0,0,1-.72,3.9,7,7,0,0,1-4,4,11.54,11.54,0,0,1-3.9.72c-2.22.1-2.88.13-8.49.13s-6.27,0-8.49-.13a11.54,11.54,0,0,1-3.9-.72A6.58,6.58,0,0,1,6.2,35.8a6.58,6.58,0,0,1-1.57-2.41,11.54,11.54,0,0,1-.72-3.9c-.1-2.22-.13-2.88-.13-8.49s0-6.27.13-8.49a11.54,11.54,0,0,1,.72-3.9A6.5,6.5,0,0,1,6.2,6.2,6.58,6.58,0,0,1,8.61,4.63a11.54,11.54,0,0,1,3.9-.72c2.22-.1,2.88-.13,8.49-.13"/><path d="M21,28a7,7,0,1,1,7-7A7,7,0,0,1,21,28Zm0-17.78A10.78,10.78,0,1,0,31.78,21,10.77,10.77,0,0,0,21,10.22m13.73-.43a2.52,2.52,0,1,1-2.52-2.52,2.52,2.52,0,0,1,2.52,2.52"/></g>
};

export const ArrowIcon = props => {
  return <polygon {...props} points="21.03 0 16.82 4.22 28.61 16.03 0 16.03 0 21.97 28.61 21.97 16.82 33.78 21.03 38 40 19 21.03 0"/>
};

export const NextArrowIcon = props => {
  return <polygon {...props} points="5.01 4.71 0.29 0 0 0.29 4.71 5 0 9.71 0.29 10 5.01 5.29 5.3 5 5.01 4.71"/>
};

export const PrevArrowIcon = props => {
  return <polygon {...props} points="0.29 5.29 5.01 10 5.3 9.71 0.59 5 5.3 0.29 5.01 0 0.29 4.71 0 5 0.29 5.29"/>
};
